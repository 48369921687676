import React, { Suspense } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BrowserRouter, Route } from "react-router-dom";

import * as config from "./config";
import { requestFirebaseNotificationPermission } from "./firebaseInit";
import { DataProvider } from './data/DataContext';
import Header from "./modules/Header";
import PostsList from "./modules/PostsList";
import InstallPWA from "./modules/InstallPWA";
import { Messaging } from './modules/Messaging';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import Post from "./modules/Post";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: "fr",
			install_button: false,
		};
	}

	async componentDidMount() {

		requestFirebaseNotificationPermission()
			.then((firebaseToken) => {
				axios({
					method: 'post',
					url: config.API_SAVE_TOKEN+firebaseToken,
					config: {
						headers: {
							'Access-Control-Allow-Origin': '*',
						}
					}
				}).then(response => console.log('sauvé'))
			})
			.catch((err) => {
				return err;
			});

		window.addEventListener('beforeinstallprompt', e => {
			// For older browsers
			e.preventDefault();
			this.installPrompt = e;
			// See if the app is already installed, in that case, do nothing
			if ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true) {
				return false;
			}
			// Set the state variable to make button visible
			this.setState({
				installButton: true
			})
		})

	}

	render() {
		return (
			<BrowserRouter>
				<DataProvider>
					<div>
						<ToastContainer 
							autoClose={5000} 
							position="bottom-center" 
							closeOnClick={false}
							hideProgressBar
						/>
						<Messaging />
						<Header />
						<Suspense>
							<Route exact path="/" component={PostsList} />
							<Route exact path="/:slug" component={Post} />
						</Suspense>
						<InstallPWA />
					</div>
				</DataProvider>
			</BrowserRouter>
		);
	}
}

export default App;
