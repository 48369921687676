import React from "react";
import logo from "../img/logo-horeca.png";
import refresh from "../img/refresh.svg";

const clearStorage = function () {
	localStorage.clear();
	window.location.reload(false);
}

function Header(props){
	return(
		<header className="app__header">
			<a className="logo" href="/"><img src={logo} alt="Fédération Horeca" /></a>			
			{/* <span onClick={clearStorage}><img height="20px" width="20px" src={refresh}/></span> */}
		</header>
	)
}

export default Header;
