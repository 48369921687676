import React from "react";
import PostCard from "./PostCard";
import Loader from 'react-loader-spinner';
import { useData } from '../data/DataContext';
import { CSSTransition } from 'react-transition-group';

const clearStorage = function () {
	localStorage.clear();
	window.location.reload(false);
}

const PostsList = function (props) {
	const { data, status } = useData();
	return (
		<section className="section">

			{status !== "fetched" &&
				<Loader
					className="centered"
					type="ThreeDots"
					color="#163570"
					height={150}
					width={50}
					timeout={3000} //3 secs
				/>
			}
			{data && (
				<ul className="posts">

					{data.map(post => (
						<CSSTransition
							timeout={350}
							appear
							in={true}
							key={post.id}
						>
							<PostCard
								first={props.first === post.id ? true : false}
								key={post.id}
								name={post.name}
								title={post.title}
								categories={post.post_categories}
								tags={post.post_tags}
								excerpt={post.excerpt}
								thumbnail={post.fimg_url}
								slug={post.slug}
								date={post.date}
							/>
						</CSSTransition>
					))}
				</ul>
			)
			}
		</section>
	)
}

export default PostsList;
