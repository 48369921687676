import React, { useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useData } from '../data/DataContext';
import Moment from 'react-moment';
import 'moment/locale/fr';
import clock from "../img/clock-regular.svg";
import tag from "../img/tag-solid.svg";

const Post = function(props) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [props.match.params.slug]);
	const { loading, findPost } = useData();
	const details = findPost(props.match.params.slug);
	return (
		<main className="page">
		{loading &&
			<Loader
				className="centered"
				type="ThreeDots"
				color="#163570"
				height={150}
				width={50}
				timeout={3000} //3 secs
			/>
		}
		{details &&
			<div className="page__inner">
				<div className="page__thumbnail"><img alt={details.title.rendered} src={details.fimg_url} /></div>
				<div className="page__content">
					<div className="tags">
						{details.post_categories.map(cat => (
							<a target="_blank" href={cat.permalink} key={cat.term_id} className="tag">{cat.name}</a>	
						))}
					</div>
					
					<h1 dangerouslySetInnerHTML={{__html: details.title.rendered}} />
					<img className="icon" src={clock} /><Moment className="date" format="DD MMMM Y" locale="fr">{details.date}</Moment>
					{details.post_tags && 
						<span class="tags">
							<img className="icon" src={tag} />
						{details.post_tags.map(tag => (
							<a target="_blank" href={tag.permalink} key={tag.term_id} className="tag--mini">{tag.name}</a>	
						))}
						</span>
					}
					<div dangerouslySetInnerHTML={{__html: details.content.rendered}} />
				</div>

			</div>
		}

		</main>
	);
};

export default Post;
