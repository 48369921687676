import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { onMessageListener } from "../firebaseInit";

const ToastButton = (props) => (
	<Link to={"/" + (props.data.slug)}>{props.data.title} : {props.data.body}</Link>
);

export const Messaging = () => {
	onMessageListener()
		.then((payload) => {
			if(payload){
				toast(<ToastButton data={payload.data} />);
			}
		})
		.catch((err) => {
            console.log(err);
		});

	return <div></div>;
};
