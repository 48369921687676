import React from "react";
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import 'moment/locale/fr';
import clock from "../img/clock-regular.svg";

function PostCard(props){
	return(
	<li className="posts__item">
		<article className={"card " + (props.first ? 'card--first' : '')}>
			{props.thumbnail &&
			<div className="card__thumbnail">
				<Link to={props.slug}><img alt={props.title.rendered} src={props.thumbnail} /></Link>
				<div className="tags">
					{props.categories && props.categories.map(cat => (
						<a target="_blank" href={cat.permalink} key={cat.term_id} className="tag">{cat.name}</a>	
					))}
				</div>
			</div>
			}
			<div className="card__content">
				<img className="icon" src={clock} /><Moment className="card__date" format="DD MMMM Y" locale="fr">{props.date}</Moment>
				<Link to={props.slug}><h2 className="card__title" dangerouslySetInnerHTML={{__html: props.title.rendered}}/></Link>
				<div className="card__excerpt" dangerouslySetInnerHTML={{__html: props.excerpt.rendered}} />	
				<Link to={props.slug} className="link">Lire Plus</Link>
			</div>
		</article>
	</li>
	)
}

export default PostCard;
