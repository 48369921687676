import React from 'react';
import * as config from "../config";
import { useFetch } from '../hooks';

export const DataContext = React.createContext();

export function DataProvider(props) {
	const state = useFetch(config.API_POSTS_URL);
	return <DataContext.Provider value={state} {...props} />;
}

export function useData() {
	const context = React.useContext(DataContext);

	if (!context) {
		throw new Error(
			'useData() requires a DataProvider component as its ancestor',
		);
	}
	const enhancedContext = React.useMemo(
		() => ({
			...context,
			findPost: slug => {
				if (context.data) {
					return context.data.find(
						post => post.slug === slug,
					);
				} else {
					return null;
				}
			},
			lastPost: () => {
				return context.data[0];
			}
		}),
		[context],
	);
	return enhancedContext;
}
