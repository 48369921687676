import { useEffect, useRef, useReducer } from 'react';

export const useFetch = (url) => {

	const initialState = {
		status: 'idle',
		error: null,
		data: [],
		last_post: null
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'FETCHING':
				return { ...initialState, status: 'fetching' };
			case 'FETCHED':
				return { ...initialState, status: 'fetched', data: action.payload, last_post: action.last_post };
			case 'FETCH_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
	}, initialState);

	useEffect(() => {
		let cancelRequest = false;
		if (!url) return;

		const fetchData = async () => {
			dispatch({ type: 'FETCHING' });
			const cachedHits = localStorage.getItem('posts');
			const cachedLastPost = localStorage.getItem('last_post');

			if (cachedHits) {
				dispatch({ type: 'FETCHED', payload: JSON.parse(cachedHits) });
			} 
			
			try {
				const response = await fetch(url);
				const data = await response.json();
				
				if (cancelRequest) return;

				if (cachedLastPost != data[0].id || !cachedLastPost) {
					console.log('detection nouveaux posts');
					localStorage.setItem('posts', JSON.stringify(data));
					localStorage.setItem('last_post', JSON.stringify(data[0].id));
					dispatch({ type: 'FETCHED', payload: data, last_post: data[0].id });
				}
			} catch (error) {
				if (cancelRequest) return;
				dispatch({ type: 'FETCH_ERROR', payload: error.message });
			}
		};

		fetchData();

		return function cleanup() {
			cancelRequest = true;
		};
	}, [url]);

	return state;
};