import firebase from 'firebase/app';
import 'firebase/messaging';

// Config firebase
const firebaseConfig = {
	apiKey: "AIzaSyCJBp5s165aXFouPMv4oqwLnNaeQ70vGw8",
    authDomain: "horeca-bruxelles.firebaseapp.com",
    databaseURL: "https://horeca-bruxelles.firebaseio.com",
    projectId: "horeca-bruxelles",
    storageBucket: "horeca-bruxelles.appspot.com",
    messagingSenderId: "536201902779",
    appId: "1:536201902779:web:2a3cdfae51bdd29d00079e"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

export const requestFirebaseNotificationPermission = () =>
	new Promise((resolve, reject) => {
		messaging
			.requestPermission()
			.then(() => messaging.getToken())
			.then((firebaseToken) => {
				console.log(firebaseToken);
				resolve(firebaseToken);
			})
			.catch((err) => {
				reject(err);
			});
	});

export const onMessageListener = () =>
	new Promise((resolve) => {
		messaging.onMessage((payload) => {
			resolve(payload);
		});
	});
