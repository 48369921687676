import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/main.scss";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// Service worker (de base avec l'app react)
serviceWorker.register();

// Service worker specifique firebase
export const registerServiceWorker = () => {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker
			.register("firebase-messaging-sw.js")
			.then(function (registration) {
				// eslint-disable-next-line no-console
				console.log("[SW]: SCOPE: ", registration.scope);
				return registration.scope;
			})
			.catch(function (err) {
				return err;
			});
	}
};

